import PropTypes from 'prop-types'
import React from 'react'
import qwiklabs from '../assets/icons/qwiklabs.svg'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-diamond"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Hi, i'm Davidson.</h1>
        <p>A web developer, cloud engineer, gamer and backpacker.</p>
      </div>
      <ul className="icons flex-center">
        <li>
          <a
            href="https://github.com/devs1993"
            target="_blank"
            rel="noreferrer"
            className="icon fa-github"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://stackoverflow.com/users/6468274/devs1993"
            target="_blank"
            rel="noreferrer"
            className="icon fa-stack-overflow"
          >
            <span className="label">stack overflow</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/davidsonmohanty/"
            target="_blank"
            rel="noreferrer"
            className="icon fa-linkedin"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.qwiklabs.com/public_profiles/178e261a-e4d1-47c4-a3eb-7207ae306d99"
            target="_blank"
            rel="noreferrer"
            className="icon"
          >
            <div className="flex-center" style={{ height: '2.25rem' }}>
              <img
                src={qwiklabs}
                height="18px"
                width="18px"
                alt="Qwiklabs profile"
                title="Qwiklabs profile"
              />
            </div>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/davidsonmohanty"
            target="_blank"
            rel="noreferrer"
            className="icon fa-twitter"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
        <li>
          <a
            href="https://drive.google.com/file/d/14Ba-lKJJZZhdURH9BfmnSFZCWPNLY3rp/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
            className="icon fa-file-pdf-o"
            title="Download resume"
          >
            <span className="label">Resume</span>
          </a>
        </li>
      </ul>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('projects')
            }}
          >
            Projects
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header

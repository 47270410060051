import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Typography, Grid, Chip } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    padding: '1rem',
    minHeight: '15rem',
  },
  label: {
    fontSize: '12px',
  },
  list: {
    margin: '0px',
    fontSize: '16px',
    height: '100%',
  },
}))

export default function Projects() {
  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      justify="center"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper} elevation={3}>
          <Typography>FocusFutbol</Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <LinkIcon fontSize="small" style={{ color: '#08c002' }} />
            <a href="https://focus.futbol/" target="_blank" rel="noreferrer">
              focus.futbol
            </a>
          </div>
          <Typography className={classes.label}>
            Type :
            <Chip
              label="Side Project (WIP)"
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '5px',
              }}
            />
          </Typography>
          <ul className={classes.list}>
            <li>A collection of latest football news and articles</li>
            <li>A place where fans can debate and conduct polls</li>
            <li>Built using ionicframework + React.js</li>
            <li>Deployed with Firebase</li>
          </ul>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper} elevation={3}>
          <Typography>ServiceTecQ</Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <LinkIcon fontSize="small" style={{ color: '#08c002' }} />
            <a
              href="https://www.servicetecq.com/"
              target="_blank"
              rel="noreferrer"
            >
              servicetecq.com
            </a>
          </div>
          <Typography className={classes.label}>
            Type :
            <Chip
              label="Freelance"
              variant="outlined"
              color="primary"
              size="small"
              style={{
                margin: '5px',
              }}
            />
          </Typography>
          <Typography>
            <ul className={classes.list}>
              <li>A site for a small business based in Kerela</li>
              <li>Built using Gatsby</li>
              <li>Deployed with Netlify</li>
            </ul>
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}
